import { Link } from "gatsby"
import { transitions } from "polished"
import React from "react"
import styled from "styled-components"
import Container from "../components/Container"

import Layout from "../layouts/default"
import SEO from "../components/seo"

const Main = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BackLink = styled(Link)`
  color: ${({ theme }) => theme.colors.grey};
  ${transitions(["color"], ".2s ease")}

  &:hover {
    color: ${({ theme }) => theme.colors.brand};
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Main as="main">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <BackLink to="/">Return home</BackLink>
    </Main>
  </Layout>
)

export default NotFoundPage
